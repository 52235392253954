@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato';
   /* background: url('path-to-your-background-image.png') no-repeat center center fixed; */
  background-size: cover;
}
.container{
  background-image: url("../assets/svg/background.jpg");
  font-weight: 400;
  font-family: 'Lato';
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 
}
.login-header{
  color: #ccc;
  font-size: 14.5px;
}
.image-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.login-box {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.12);
  padding: 20px 20px 30px 20px ;
  color: white;
  border-radius: 10px;
  width: 380px;
  /* padding-bottom: 30px; */
  margin-top: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #f7f4f4;
}

.input-group {
  margin-bottom: 20px;
  margin-right: 0; /* Remove extra right margin */
  width: 100%; /* Ensure the input group covers the full width */
}

.input-group input {
  width: 100%; /* Make the input fill the container */
  padding: 15px; /* Adjust padding to ensure it fits within the box */
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box; /* Include padding and border in width calculation */
  background-color: transparent; /* Match the background */
  color: #ccc; /* Match the text color */
}

.login-button {
  width: 100%; 
  padding: 15px 0px 15px;
  margin-top: 20px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Lato';
}

.input-group label {
  display: block;
  padding: 20px;
  color: #ccc;
  margin-bottom: 5px;
  font-family: 'Lato', sans-serif;
}
.input-text {
  background-color: transparent;
  color: #ccc;
  padding: 10px 15px; /* Adjust padding */
  font-size: 12px;
  width: 100%; /* Ensure it stays within the container */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* .input-group input {
  width: calc(100% - 30px); 
  padding: 10px 15px; 
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}  */



/* .login-button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #ed7505;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Lato';
} */

.login-button:hover {
  background-color:  "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
}
.signIn{
 font-size: 28; padding-bottom: 2 ;font-family:'Overpass '  
}