.main{
  background-color: rgb(255, 255, 255);
}
.game-detail-container {
  padding: 20px;
  background-color: #ffffff;
}

.form-field {
  margin-bottom: 15px;
  font-family: 'Lato';

}
.form-template{
  background-color: #287EAD;
  color: #ffffff;
}
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.image-section {
  /* height: fit-content; */
  /* height: 128px; */
  /* display: flex; */
  /* /* justify-content: center; */
  
}

.image-upload {
  position: relative;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 128px;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.uploaded-img {
  max-width: 100%;
  max-height: 125px ;
  min-height: 125px;
  border-radius: 8px;
  border:'1px dotted';
  width: 100%;
}

.submit-button {
  
  align-self: flex-end;
  padding: 10px;
  font-size: 18px;
}
.typo{
  margin: 10px; ;
}