@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
   /* background: url('path-to-your-background-image.png') no-repeat center center fixed; */
  background-size: cover;
}
body *{
  font-family: 'Lato', sans-serif !important;
  
}
.cursor-pointer {
    cursor: pointer;
  }
  .p-relative {
    position: relative;
  }
  .d-flex {
    display: flex;
    padding: 20px 
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-left {
    justify-content: left;
  }
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-end-important {
    justify-content: end !important;
  }
  .justify-content-space-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .padding {
    padding: auto
  }
  .d-none {
    display: none;
  }
  .loaderIcon{
    display: flex;
    flex-direction:  column;
    justify-content:  center;
    justify-items: center;
    align-items:  center;
    height: 100%;
    width: 100%;
  
  }
  .error-message {
    color: red;
    font-size: 12px; 
    margin-top: 5px;
    align-self: start;
    

  }