.all-games-view {

 flex:1;
 
}

.all-games-inner-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-view {
  display: flex;
}
.even-row {
  background-color: #de0a0a; /* Adjust as needed */
}

.odd-row {
  background-color: #401c1c; /* Adjust as needed */
}

.btn {
  padding: 10px 20px;
  /* background-color: #287EAD; */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lato';

}

.MuiDataGrid-root {
  background-color: white;
  border-radius: 10px;
  border: none;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

.custom-text-field .MuiOutlinedInput-root {
  border-width: 2px;
  border-color: rgba(40, 126, 173, 0.12) !important; /* Change border color here */
}

.custom-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 0, 0) !important; /* Change border color on hover here */
}

.custom-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(133, 130, 130) !important; /* Change border color when focused here */
}

.custom-text-field .MuiInputBase-input {
  /* Change text color here */
}

.custom-text-field .MuiInputBase-input::placeholder {
  color: gray !important; /* Change placeholder color here */
}
.cursor-pointer{
  margin-right: 10px;
}